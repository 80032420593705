import {
	rePass,
	nextStep,
	smsCode,
	registerConfig
} from "@/api/auth/login"
import {
	captcha
} from "@/api/website"

export default {
	data() {
		var isMobile = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("หมายเลขโทรศัพท์ต้องไม่ว่างเปล่า"))
			} else {
				const reg = /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/

				if (reg.test(value)) {
					callback()
				} else {
					callback(new Error("โปรดป้อนหมายเลขโทรศัพท์มือถือที่ถูกต้อง"))
				}
			}
		}

		let self = this
		var setPass = function(rule, value, callback) {
			let regConfig = self.registerConfig
			if (!value) {
				return callback(new Error("กรุณาใส่รหัสผ่านเข้าสู่ระบบใหม่"))
			} else {
				if (regConfig.pwd_len > 0) {
					if (value.length < regConfig.pwd_len) {
						return callback(new Error("ความยาวของรหัสผ่านต้องไม่น้อยกว่านั้น" + regConfig.pwd_len + "บิต"))
					} else {
						callback()
					}
				} else {
					if (regConfig.pwd_complexity != "") {
						let passwordErrorMsg = "ต้องระบุรหัสผ่าน",
							reg = ""
						if (regConfig.pwd_complexity.indexOf("number") != -1) {
							reg += "(?=.*?[0-9])"
							passwordErrorMsg += "เลข"
						} else if (regConfig.pwd_complexity.indexOf("letter") != -1) {
							reg += "(?=.*?[a-z])"
							passwordErrorMsg += "、b"
						} else if (regConfig.pwd_complexity.indexOf("upper_case") != -1) {
							reg += "(?=.*?[A-Z])"
							passwordErrorMsg += "、มาจูสคูล"
						} else if (regConfig.pwd_complexity.indexOf("symbol") != -1) {
							reg += "(?=.*?[#?!@$%^&*-])"
							passwordErrorMsg += "、อักขระพิเศษ"
						} else {
							reg += ""
							passwordErrorMsg += ""
						}

						if (reg.test(value)) {
							return callback(new Error(passwordErrorMsg))
						} else {
							callback()
						}
					}
				}
			}
		}

		var checkPass = function(rule, value, callback) {
			if (!value) {
				return callback(new Error("กรุณาใส่รหัสผ่านยืนยัน"))
			} else {
				if (value !== self.formData.pass) {
					callback(new Error("รายการรหัสผ่านสองรายการไม่สอดคล้องกัน"))
				} else {
					callback()
				}
			}
		}

		return {
			formData: {
				mobile: "",
				vercode: "",
				dynacode: "",
				pass: "",
				repass: "",
				key: ""
			},
			step: 1,
			activeName: "first",
			isShowPhone: "",
			captcha: {
				id: "",
				img: ""
			}, // แคปต์ชา
			dynacodeData: {
				seconds: 120,
				timer: null,
				codeText: "รับโค้ดแบบไดนามิก",
				isSend: false
			}, // รหัสแบบไดนามิก
			registerConfig: {},
			rules: {
				mobile: [{
					required: true,
					validator: isMobile,
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "กรุณากรอกรหัสยืนยัน",
					trigger: "blur"
				}],
				dynacode: [{
					required: true,
					message: "กรุณากรอกรหัสไดนามิกของ SMS",
					trigger: "blur"
				}],
				pass: [{
					required: true,
					validator: setPass,
					trigger: "blur"
				}],
				repass: [{
					required: true,
					validator: checkPass,
					trigger: "blur"
				}]
			}
		}
	},
	created() {
		this.getCaptcha()
		this.getRegisterConfig()
	},
	watch: {
		"dynacodeData.seconds": {
			handler(newValue, oldValue) {
				if (newValue == 0) {
					clearInterval(this.dynacodeData.timer)
					this.dynacodeData = {
						seconds: 120,
						timer: null,
						codeText: "รับโค้ดแบบไดนามิก",
						isSend: false
					}
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		/**
		 * ต่อไป
		 */
		nextStep(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					nextStep({
							mobile: this.formData.mobile
						})
						.then(res => {
							if (res.code == -1) {
								this.step = 2
							} else {
								this.$notify({
									message: res.message,
									title: "คำเตือน",type: "warning"
								})
							}
						})
						.catch(err => {
							if (err.code == 0) {
								this.$notify({
									message: "หมายเลขโทรศัพท์ไม่ได้ลงทะเบียน",
									title: "คำเตือน",type: "warning"
								})
							} else {
								this.$notify.error({title: 'Error',message: err.message});
							}
						})
				} else {
					return false
				}
			})
		},
		/**
		 * รับรหัสยืนยันแบบไดนามิก ต่อไป
		 */
		nextStepToSetPass(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.step = 3
				} else {
					return false
				}
			})
		},
		/**
		 * รีเซ็ตรหัสผ่านของคุณ
		 */
		resetPass(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					rePass({
							password: this.formData.pass,
							code: this.formData.dynacode,
							key: this.formData.key,
							mobile: this.formData.mobile
						})
						.then(res => {
							if (res.code >= 0) {
								this.step = 4
								this.$notify({
									message: res.message,
									title: 'เรียบร้อยแล้ว',type: "success"
								})
							}
						})
						.catch(err => {
							this.$notify.error({title: 'Error',message: err.message});
						})
				} else {
					return false
				}
			})
		},
		/**
		 * รับรหัสยืนยัน
		 */
		getCaptcha() {
			captcha({
					captcha_id: this.captcha.id
				})
				.then(res => {
					if (res.code >= 0) {
						this.captcha.id = res.data.id
						this.captcha.img = res.data.img
						this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
					}
				})
				.catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
				})
		},
		/**
		 * ส่งรหัสไดนามิกของโทรศัพท์มือถือ
		 */
		sendMobileCode(formName) {
			if (this.dynacodeData.seconds != 120) return
			this.$refs[formName].clearValidate("dynacode")
			this.$refs[formName].validateField("vercode", valid => {
				if (!valid) {
					if (this.isSend) return
					this.isSend = true

					smsCode({
							captcha_id: this.captcha.id,
							captcha_code: this.formData.vercode,
							mobile: this.formData.mobile
						})
						.then(res => {
							if (res.code >= 0) {
								this.formData.key = res.data.key

								if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
									this.dynacodeData.timer = setInterval(() => {
										this.dynacodeData.seconds--
										this.dynacodeData.codeText = this.dynacodeData.seconds + "sสามารถซื้อซ้ําได้"
									}, 1000)
								} else {
									this.$notify({
										message: res.message,
										title: "คำเตือน",type: "warning"
									})
									this.isSend = false
									this.getCaptcha()
								}
							}
						})
						.catch(err => {
							this.isSend = false
							this.getCaptcha()
							this.$notify.error({title: 'Error',message: err.message});
						})
				} else {
					return false
				}
			})
		},
		/**
		 * รับการกําหนดค่าการลงทะเบียน
		 */
		getRegisterConfig() {
			registerConfig()
				.then(res => {
					if (res.code >= 0) {
						this.registerConfig = res.data.value
					}
				})
				.catch(err => {
					console.log(err.message)
				})
		}
	}
}
